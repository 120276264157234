import React from 'react'
import {getUrlParams, checkMobileBrowser} from '../util'


class IndexPage extends React.Component {
	getMobileOperatingSystem() {
		var userAgent =  ''
		if (typeof window !== 'undefined') {
			userAgent = navigator.userAgent || navigator.vendor || window.opera
		}


		// Windows Phone must come first because its UA also contains "Android"
		if (/windows phone/i.test(userAgent)) {
			return "Windows"
		}

		if (/android/i.test(userAgent)) {
			return "Android"
		}

		// iOS detection from: http://stackoverflow.com/a/9039885/177710
		if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
			return "iOS"
		}

		return "unknown"
	}

	render() {
		const os = this.getMobileOperatingSystem()
		const urlParams = getUrlParams()

		if (checkMobileBrowser()) {
			switch (os) {
			case 'Android':
			{
      	return (
      			<a className="button txt-android" href="mms:?body=Checkout%20this%20awesome%20website%20%40%20http%253A%252F%252Fgilgreenberg.com%252F">
            android click here
					</a>
      		)
			}
			default:
      	window.location.href = `sms:&body=${urlParams.message}`

				return true
			}
		}
		return "You're on a desktop. no link for you"
		//

		// }
	}
}

export default IndexPage
